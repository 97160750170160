<template>
  <div id="app">
    <v-app>
      <v-app-bar app color="white" absolute :height="146">
        <v-img src="https://storage.googleapis.com/skyway-cloud-dev/MiraiGo.png" :max-width="267">
        </v-img>
        <v-spacer/>
        <router-link class="nav-class text-h5 mx-6" v-for="item in list" :key="item.name" :to="item.to" active-class="router-active">
           {{item.name}}
        </router-link>
      </v-app-bar>
      <v-main class="mt-4">
        <v-container>
          <router-view/>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},

  data: () => ({
    list: [
      {
        name: 'Box R Lite 4K',
        to: '/product1',
      },
      {
        name: 'Box R 4K Plus',
        to: '/product6',
      },
      // {
      //   name: 'LED TV 2',
      //   to: '/product2',
      // },
      {
        name: 'LED TV 50H',
        to: '/product3',
      },
      {
        name: 'LED TV 86E',
        to: '/product4',
      },
      {
        name: 'LED TV 55I',
        to: '/product5',
      },
      {
        name: 'Contact Us',
        to: '/contantUs',
      }
    ]
  }),
};
</script>
<style scoped>
.nav-class {
  text-decoration: none;
  color: #000;
  font-size: 21px;
  border-bottom: 4px solid transparent;
}
.nav-class:hover {
 border-bottom: 4px solid;
}
.router-active {
  color: rgb(108, 236, 63);
  border-bottom: 4px solid;
}
</style>
