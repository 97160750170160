<template>
  <div>
    <v-row>
      <v-col cols="6">
       <v-card class="pa-4" height="400">
         <v-img :src="require('@/assets/page5/en14164-22081.jpg')" height="100%" contain></v-img>
       </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="pa-4" height="400">
          <v-card-title class="text-h3">
            LED TV 55I
          </v-card-title>
          <v-card-subtitle class="text-h6 mt-2">
            Product photos on this website may vary from the real products. Products information may vary from the products manual available in the market. Please use the products subjected to the specific information on the user manual. For details, please contact the related persons.
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-8">
      <v-card-title>Detail Pictures of Product</v-card-title>
      <v-card flat class="pa-4">
        <v-card outlined class="mb-4">
          <v-list three-line>
            <template>
<!--              <v-list-item>-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title>Panel size</v-list-item-title>-->
<!--                  <v-list-item-subtitle>55"</v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
<!--              </v-list-item>-->
<!--              <v-divider/>-->
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Display parameters</v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>Backlight category</v-list-item-title>
                  <v-list-item-subtitle>MINI LED</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>Aspect ratio</v-list-item-title>
                  <v-list-item-subtitle>16:9</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider/>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Power waste</v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>Power</v-list-item-title>
                  <v-list-item-subtitle>≤190W</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>Operating voltage</v-list-item-title>
                  <v-list-item-subtitle>100V-240VAC 60/50Hz</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider/>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Interface type</v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>Input interface</v-list-item-title>
                  <v-list-item-subtitle>HDMI/VGA/AV-IN/TV/USB/USB2.0/YPbPr/PC-Audio/Audio-IN/SERVICE/Tuner/RJ-45/CI</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>Output interface</v-list-item-title>
                  <v-list-item-subtitle>Earphone/Audio-OUT/Video-OUT/Coaxial-OUT/SPDIF-OUT</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
        <v-img :src="require('@/assets/page5/en14163-88639.jpg')" height="400" contain></v-img>
        <v-img :src="require('@/assets/page5/en14165-42935.jpg')" height="400" contain></v-img>
        <v-img :src="require('@/assets/page5/en14166-74588.jpg')" height="400" contain></v-img>
      </v-card>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
