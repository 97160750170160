<template>
  <div>
    <v-card>
      <v-card-title class="text-h4">
        contact info:
      </v-card-title>
      <v-card-title class="mt-6">
        sales@wipes-mirai.com <br/>
        info@wipes-mirai.com
      </v-card-title>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
