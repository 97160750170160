import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App'
import vuetify from './plugins/vuetify'
import product1 from "@/views/productDetail1.vue";
import product2 from "@/views/product2";
import product3 from "@/views/product3";
import product4 from "@/views/product4";
import product5 from "@/views/product5";
import product6 from "@/views/product6";
import contantUs from "@/views/contantUs";

Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'hash',
    routes: [
        { path: '/', redirect: "/product1" },
        { path: '/product1', component: product1},
        { path: '/product2', component: product2},
        { path: '/product3', component: product3},
        { path: '/product4', component: product4},
        { path: '/product5', component: product5},
        { path: '/product6', component: product6},
        { path: '/contantUs', component: contantUs},
    ]
})

new Vue({
    vuetify,
    router,
    render: h => h(App, {})
}).$mount('#app')
