<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card class="pa-4" height="400">
          <v-img :src="require('@/assets/page1/bg1.webp')" height="100%"></v-img>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="pa-4" height="400">
          <v-card-title class="text-h3">
            Box R Lite 4K
          </v-card-title>
          <v-card-subtitle class="text-h6 mt-2">
            Powered by a 2GHz quad-core processor, Box R Lite 4K comes with
            everything you need, offering you an easier way to dive into the
            entertainment you love with the fast startup.
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-8">
      <v-card-title>Detail Pictures of Product</v-card-title>
      <v-card flat class="pa-4">
        <v-img :src="require('@/assets/page1/bg2.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg3.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg4.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg5.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg6.gif')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg7.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg8.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg9.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg10.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg11.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg12.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg13.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg14.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg15.webp')"  contain></v-img>
        <v-img :src="require('@/assets/page1/bg16.webp')"  contain></v-img>
      </v-card>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
