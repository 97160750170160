<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card class="pa-4" height="400">
          <v-img :src="require('@/assets/page5/en12858.jpg')" height="100%" contain></v-img>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="pa-4" height="400">
          <v-card-title class="text-h3">
            LED TV
          </v-card-title>
          <v-card-subtitle class="text-h6 mt-2">
           Product photos on this website may vary from the real products. Products information may vary from the products manual available in the market. Please use the products subjected to the specific information on the user manual. For details, please contact the related persons.
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-8">
      <v-card-title>Detail Pictures of Product</v-card-title>
      <v-card flat class="pa-4">
        <v-card outlined class="mb-4">
          <v-list three-line>
            <template>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>12312312</v-list-item-title>
                  <v-list-item-subtitle>1231231</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>12312312</v-list-item-title>
                  <v-list-item-subtitle>1231231</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>12312312</v-list-item-title>
                  <v-list-item-subtitle>1231231</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider/>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>12312312</v-list-item-title>
                  <v-list-item-subtitle>1231231</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>12312312</v-list-item-title>
                  <v-list-item-subtitle>1231231</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>12312312</v-list-item-title>
                  <v-list-item-subtitle>1231231</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
        <v-img :src="require('@/assets/page5/en12858.jpg')" height="400" contain></v-img>
        <v-img :src="require('@/assets/page5/en12858.jpg')" height="400" contain></v-img>
        <v-img :src="require('@/assets/page5/en12858.jpg')" height="400" contain></v-img>
        <v-img :src="require('@/assets/page5/en12858.jpg')" height="400" contain></v-img>
      </v-card>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
