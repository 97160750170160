<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card class="pa-4" height="400">
          <v-img :src="require('@/assets/boxR4kPlsu/img.png')" height="100%" contain></v-img>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="pa-4" height="400">
          <v-card-title class="text-h3">
            Box R 4K Plus
          </v-card-title>
          <v-card-subtitle class="text-h6 mt-2">
            Powered by a 2GHz quad-core processor, Box R 4K Plus is the fastest and
            most powerful player ever, offering you an easier way to dive into thee entertainment
            you love with the fast startup.
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-8">
      <v-card-title>Detail Pictures of Product</v-card-title>
      <v-card flat class="pa-4">
<!--        <v-card outlined class="mb-4">-->
<!--          <v-list three-line>-->
<!--            <template>-->
<!--              <v-list-item>-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title>12312312</v-list-item-title>-->
<!--                  <v-list-item-subtitle>1231231</v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title>12312312</v-list-item-title>-->
<!--                  <v-list-item-subtitle>1231231</v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title>12312312</v-list-item-title>-->
<!--                  <v-list-item-subtitle>1231231</v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
<!--              </v-list-item>-->
<!--              <v-divider/>-->
<!--              <v-list-item>-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title>12312312</v-list-item-title>-->
<!--                  <v-list-item-subtitle>1231231</v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title>12312312</v-list-item-title>-->
<!--                  <v-list-item-subtitle>1231231</v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title>12312312</v-list-item-title>-->
<!--                  <v-list-item-subtitle>1231231</v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
<!--              </v-list-item>-->
<!--            </template>-->
<!--          </v-list>-->
<!--        </v-card>-->
        <v-img :src="require('@/assets/boxR4kPlsu/Box-R-Lite-4K_20220817_06.webp')" contain></v-img>
        <v-img :src="require('@/assets/boxR4kPlsu/get_all_the_top_apps_and_games.webp')" contain></v-img>
        <v-img :src="require('@/assets/boxR4kPlsu/app_gif_6fe0281c-7a81-430d-b7b3-10f2a0f72f1d.gif')" contain></v-img>
        <v-img :src="require('@/assets/boxR4kPlsu/4K_gif_7399d4c6-7a31-4ae0-a0af-3254578d07b6.gif')" contain></v-img>
        <v-img :src="require('@/assets/boxR4kPlsu/Cinematic_Sound.webp')" contain></v-img>
        <v-img :src="require('@/assets/boxR4kPlsu/Box-R-4k-plus-apres-googlefeedback-changer_20220714_14.webp')" contain></v-img>
        <v-img :src="require('@/assets/boxR4kPlsu/Box-R-4k-plus-apres-googlefeedback-changer_20220714_16_b2d8d42d-90ea-4c23-8d3c-892361b7609b.webp')" contain></v-img>
        <v-img :src="require('@/assets/boxR4kPlsu/Box-R-4k-plus-apres-googlefeedback-changer_20220714_17.webp')" contain></v-img>
      </v-card>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
